<template>
  <div class="department-add-container">
    <div class="operate">
      <dsn-button
          size="small"
          icon="el-icon-back"
          type="primary"
          @click.native="handleBack"
      >{{ this.$t("commbtn.back") }}
      </dsn-button>
      <!--      <dsn-button-save-->
      <!--          @click.native="checkAdd('materialGroupForm')"-->
      <!--          v-if="hasUpdateBtnAuth"-->
      <!--      >保存-->
      <dsn-button-save
          @click.native="checkAdd(['departmentFormRef'])">{{ this.$t("commbtn.save") }}
      </dsn-button-save
      >
      <dsn-button
          size="small"
          type
          plain
          icon="el-icon-refresh"
          class="reset-button"
          @click.native="handleReset(['departmentFormRef'])"
      >{{ this.$t("commbtn.reset") }}
      </dsn-button>
    </div>
    <div class="info">
      <el-form
          :model="departmentForm"
          ref="departmentFormRef"
          :inline="true"
          :rules="userFormRules"
          class="userFormTop"
      >
        <el-row>
          <el-col :span="24">

            <el-form-item label="上级机构" prop="parentId" :label-width="labelWidth">
              <!--              <dsn-input-->
              <!--                  v-model.trim="departmentForm.parentId"-->
              <!--                  placeholder="请选择上级机构"-->
              <!--                  :disabled="isEditStation"-->
              <!--              ></dsn-input>-->

              <!--              <el-select-->
              <!--                  v-model="departmentForm.parentCategoryId"-->
              <!--                  clearable-->
              <!--                  filterable-->
              <!--                  placeholder="请选择部门"-->
              <!--                  class="department-select-input"-->
              <!--                  allow-create-->
              <!--                  value-key="key"-->
              <!--              >-->
              <!--                <el-option class="options">-->
              <!--                  <el-tree class="option-tree"></el-tree>-->
              <!--                </el-option>-->
              <!--              </el-select>-->
              <depart-tree-select v-model="departmentForm.parentId" :data="departmentOptionList"
                                  @change="onDepartmentChange"></depart-tree-select>
            </el-form-item>

            <!--            <el-form-item label="负责人" prop="directorId" :label-width="labelWidth">-->
            <!--              <dsn-input-->
            <!--                  v-model.trim="departmentForm.realname"-->
            <!--                  placeholder="请输入用户姓名"-->
            <!--              ></dsn-input>-->
            <!--            </el-form-item>-->

          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="机构名称" prop="departmentName" :label-width="labelWidth">
            <dsn-input
                v-model.trim="departmentForm.departmentName"
                placeholder="请输入机构名称"
                :disabled="isEditStation"
            ></dsn-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="部门编码" prop="departmentCode" :label-width="labelWidth">
            <dsn-input
                v-model.trim="departmentForm.departmentCode"
                placeholder="请输入机构名称"
                :disabled="isEditStation"
            ></dsn-input>
          </el-form-item>
        </el-row>
        <el-row>

          <el-form-item label="排序号" prop="orderNo" :label-width="labelWidth">
            <dsn-input
                v-model.trim="departmentForm.orderNo"
                placeholder="请输入排序号"
                :disabled="isEditStation"
            ></dsn-input>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
  </div>

</template>
<script>
import {addDepartment, getDepartmentList} from "@/api/efms/department/department.api";
import DsnSelect from "@/components/global/Select/DsnSelect";
import DepartTreeSelect from "@/views/mes/user/department/components/department-select";
import arrayToTree from "array-to-tree";

export default {
  name: 'departmentAdd',
  components: {DepartTreeSelect, DsnSelect},
  data() {
    return {
      isEditStation: false, //是否可编辑站位
      departmentForm: {
        departmentName: null,
        departmentId: null,
        departmentCode: null,
        directorId: null,
        orderNo: null,
      },
      labelWidth: "100px",
      userFormRules: {
        username: [{required: true, message: "请输入账号", trigger: "change"}],
        realname: [{required: true, message: "请输入用户姓名", trigger: "change"}],
        password: [{required: true, message: "请输入密码", trigger: "change"}],
      },
      departmentOptionList: [
        {
          label: '西丽工厂',
          id: 1,
          children: [
            {label: '智能一车间', id: 2},
            {label: '人力资源部', id: 3},
            {label: '业务部', id: 4},
            {label: '品质管理部', id: 5},
            {label: '信息部', id: 6}
          ]
        }, {
          label: '东莞一车间',
          id: 21
        }
      ],
      multiple: false
    }
  },
  created() {
    this.operateType = this.$route.query.operateType;
    this.getDepartmentSelectOptions();
  },
  methods: {
    handleBack() {
      this.$router.pushAndClear(
          {name: "departmentList"},
          {
            deleteItem: this.$route,
            current: this.$route,
          }
      );
    },
    checkAdd(formName) {
      let count = 0;
      formName.forEach((element) => {
        this.$refs[element].validate((valid) => {
          if (valid) {
            count++;
          } else {
            return false;
          }
        });
      });
      if (count >= 1) {
        this.messageBox({
          type: "success",
          title: "保存",
          content: "是否保存数据?",
        })
            .then(() => {
              this.handleDataSave();
            })
            .catch(() => {
              this.notification().warn({
                body: "已取消保存",
              });
            });
      }
    },
    handleDataSave() {
      const postData = {};
      // postData['username'] = this.departmentForm.username
      // postData['password'] = this.departmentForm.password
      // postData['realname'] = this.departmentForm.realname
      // postData['sex'] = this.departmentForm.sex
      // postData['email'] = this.departmentForm.email
      // postData['phone'] = this.departmentForm.phone
      postData["departmentName"] = this.departmentForm.departmentName
      if (this.departmentForm.parentId != null) {
        postData["parentId"] = this.departmentForm.parentId;
        postData["departmentCode"] = this.departmentForm.departmentCode
      } else {
        postData["parentId"] = null
        postData["departmentCode"] = this.departmentForm.departmentCode
      }
      addDepartment(postData).then(result => {
        const {code, errMsg} = result.data;
        if (code === 200) {
          this.notification().success({
            body: "保存成功",
          });
          this.handleBack();
          return;
        } else {
          this.notification().error({
            body: errMsg,
          });
        }
      }).catch(err => {
        this.notification().error({
          body: this.$t('errors.fields.serverInternalError'),
        });
      })
    },
    handleReset(formName) {
      if (this.operateType === "add") {
        formName.forEach((element) => {
          this.$refs[element].resetFields();
        });
      }
    },
    getDepartmentSelectOptions() {
      getDepartmentList().then(result => {
        const {data, status} = result;
        if (status === 200 && data.data != null) {
          let resultList = data.data;
          if (resultList != null && resultList.length > 0) {
            let mapList = resultList.map((v) => {
              return {
                id: v.id,
                label: v.departmentName,
                labelEn: v.departmentNameEn,
                labelOther: v.departmentNameOther,
                key: v.id,
                parentId: (v.parentId == null || v.parentId === "") ? null : v.parentId
              }
            })
            let result = this.toTreeList(mapList);
            this.departmentOptionList = [...result]
          }
        } else {
          console.info("empty")
        }
      }).catch(err => {
        this.notification().error({
          body: err,
        });
      })

    },
    toTreeList(dataList) {
      return arrayToTree(dataList, {
        parentProperty: 'parentId',
        customID: 'id'
      })
    },
    onDepartmentChange(data) {
      console.info(data)
    }
  }
}
</script>
<style lang="scss">

.department-add-container {
  .operate {
    padding: 16px;

    .el-button {
      margin: 0 8px 0 0;
    }
  }

  .info {
    background-color: #ffffff;
    padding: 16px;

    .userFormTop {
      .el-form-item {
        margin: 0 24px 16px 0;
      }
    }

    .userForm {
      .el-form-item {
        margin: 0 24px 16px 0;

        .el-select {
          width: 320px;
        }

        .el-input {
          width: 320px;
        }
      }
    }
  }
}
</style>
