<template>
  <div class="department-list-container">
    <DsnPanel>
      <div slot="header" class="title clearfix">
        <el-form
            :inline="true"
            :model="queryParam"
            ref="testQueryForm"
            :rules="{}"
            @submit.native.prevent
        >
          <el-form-item :label="`${this.$t('departments.fields.departmentName')}:`" prop="deptId">
            <dsn-input v-model="queryParam.departmentName" placeholder="请输入部门名称" size="medium"></dsn-input>
          </el-form-item>
          <!--          <el-form-item label="用户姓名:" prop="realname">-->
          <!--            <dsn-input v-model="queryParam.realname" placeholder="请输入姓名"></dsn-input>-->
          <!--          </el-form-item>-->
          <el-form-item>
            <dsn-button
                type="primary"
                icon="el-icon-search"
                @click="handleQuery(['testQueryForm'])" size="medium"
            >{{ this.$t("commbtn.query") }}
            </dsn-button>
            <dsn-button
                type
                plain
                icon="el-icon-refresh"
                class="reset-button"
                @click="handleReset('materilList')" size="medium"
            >{{ this.$t("commbtn.reset") }}
            </dsn-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="operation">
        <dsn-button
            type="success"
            icon="el-icon-folder-add"
            @click.native="handleAdd" size="medium"
        >{{ this.$t("commbtn.add") }}
        </dsn-button>
        <dsn-button
            type="primary"
            icon="el-icon-edit"
            :disabled="selectionList.length !== 1" size="medium"
            @click="handleEdit"
            style="margin-left:0"
        >{{ this.$t("commbtn.edit") }}
        </dsn-button>
        <dsn-button
            type="danger"
            icon="el-icon-delete"
            style="margin-left:0"
            :disabled="selectionList.length <= 0" size="medium"
            @click="handleDeleteCheck"
        >{{ this.$t("commbtn.delete") }}
        </dsn-button>
      </div>
      <dsn-table
          @row-click="selectCheckBox"
          ref="multipleTable"
          :data="pagingConfig.tableData"
          v-loading="pagingConfig.tableLoading"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"/>
        <!--        <el-table-column type="index" label="序号"/>-->
        <el-table-column prop="departmentName" :label="this.$t('departments.fields.departmentName')" align="center"
                         width="250"/>
        <el-table-column prop="departmentCode" :label="this.$t('departments.fields.departmentCode')" align="right"
                         width="200"/>
        <el-table-column prop="directorId" :label="this.$t('departments.fields.director')" align="center"
                         width="200"/>
        <el-table-column/>
      </dsn-table>
      <DsnFooter>
        <dsn-pagination
            v-if="showPage"
            background
            layout="->,total,prev,pager,next,sizes"
            :total="pageTotalCount"
            :page-size="pagingConfig.pageSize"
            :page-sizes="[5, 10, 15, 20]"
            :current-page="pagingConfig.current"
            @size-change="handlePageSizeChange"
            @current-change="handleCurrentChange"
        ></dsn-pagination>
      </DsnFooter>
    </DsnPanel>
    <el-dialog title="删除" :visible.sync="deleteDialog" :width="defaltDialogWidth" v-el-drag-dialog>
      <span>是否确认删除{{ selectionList.length }}条数据？</span>
      <span slot="footer" class="dialog-footer">
        <dsn-button @click="deleteDialog = false">取 消</dsn-button>
        <dsn-button type="primary" @click="handleDelete">{{ this.$t("commbtn.comfirm") }}</dsn-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {mapMutations} from "vuex";
import DsnFooter from "@/views/layout/dsn-footer";
import {deleteUserById} from "@/api/efms/users/users.api";
import {getDepartmentList} from "@/api/efms/department/department.api";

export default {
  name: 'equipmentPositionRefSettings',
  components: {DsnFooter},
  inject: ["defaltDialogWidth"],
  data() {
    return {
      listData: [],
      queryParam: {
        username: null,
        realname: null,
        departmentName: null
      },
      showPage: true,
      pagingConfig: {
        current: 1,
        pageSize: 10,
        count: 0,
        tableLoading: false,
        total: 0,
        tableData: [],
      },
      deleteDialog: false,
      selectionList: [],
      departmentNodeList: [],
    }
  },
  computed: {
    pageTotalCount: function () {
      return this.pagingConfig.total
    }
  },
  created() {
    this.getSysUserList();
  },
  activated() {
    this.getSysUserList();
  },
  methods: {
    ...mapMutations(["DEPARTMENTLIST"]),
    sexFormatter(data) {
      return data === 1 ? this.$t('users.fields.male') : this.$t('users.fields.female')
    },
    handleQuery(formNames) {
      this.$refs[formNames].validate(valid => {
        if (valid) {
          this.getSysUserList();
        } else {
          return false;
        }
      });
    },
    handleReset(formName) {
      this.$refs[formName].resetFields();
      this.handleQuery();
    },
    handlePageSizeChange(pageSize) {
      this.pagingConfig.pageSize = pageSize;
      this.pagingConfig.current = 1;
      this.getSysUserList();
    },
    handleCurrentChange(currentChange) {
      this.pagingConfig.current = currentChange;
      this.getSysUserList();
    },
    handleSelectionChange(val) {
      this.selectionList = val;
    },
    selectCheckBox(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    handleEdit() {
      const tempArr = JSON.parse(JSON.stringify(this.selectionList));
      this.DEPARTMENTLIST(tempArr);
      localStorage.setItem("departmentList", JSON.stringify(this.selectionList));
      this.$router.push({
        name: "departmentEdit",
        query: {operateType: "edit"}
      });
    },
    handleAdd() {
      // const tempArr = JSON.parse(JSON.stringify(this.selectionList));
      this.DEPARTMENTLIST([]);
      // localStorage.setItem("positionRefList", JSON.stringify(this.selectionList));
      this.$router.push({
        name: "departmentAdd",
        query: {operateType: "add"}
      });
    },
    handleDelete() {
      const {selectionList} = this;
      const params = selectionList.map(item => ({
        id: item.id,
      }));

      deleteUserById(params).then(result => {

      })
      console.info(params)
    },
    handleDeleteCheck() {
      this.messageBox()
          .then(() => {
            this.handleDelete();
          })
          .catch(() => {
            this.notification().warn({
              body: "已经取消删除"
            });
          });
    },
    getSysUserList() {
      console.info("query")
      // this.initFakeData();
      this.initMainList();
    },
    initFakeData() {
      for (let i = 0; i <= 100; i++) {
        this.listData.push({
          id: i,
          username: 'acc' + i,
          realname: 'acc' + i,
          deptId: +i,
          deptLeaderId: '部门' + i,
          deptLeader: "部门负责人" + i,
          activeStatus: 1,
          position: '车间' + i,
          activeStatusDesc: i === 1 ? 'active' : 'inactive'
        })
      }
    },
    initMainList(showPage) {
      if (!showPage) {
        this.pagingConfig.currentPage = 1;
      }
      const data = {
        ...this.queryParam,
        page: this.pagingConfig.current,
        pageSize: this.pagingConfig.pageSize
      };
      this.showPage = showPage;
      this.pagingConfig.tableLoading = true
      getDepartmentList(data).then((response) => {
        const {data, status} = response;
        this.pagingConfig.tableLoading = false
        if (status === 200) {
          console.info(data)
          this.showPage = true;
          this.pagingConfig.total = data.totalCount;
          this.pagingConfig.tableData = [...data.data]
          this.$forceUpdate()
          // this.notification().success({
          //   body: '请求成功',
          //   duration: 1
          // });
        } else {
          this.notification().warn({
            body: data.errMsg
          });
        }
      })
    },
    // toTreeList(dataList) {
    //   return arrayToTree(dataList, {
    //     parentProperty: 'parentId',
    //     customID: 'id'
    //   })
    // }
  }
}
</script>
<style lang="scss" scoped>

.department-list-container {
  .operation {
    width: 100%;
    height: 48px;

    .dsn-button {
      margin: 0 8px 0 0;
    }
  }
}

::v-deep.el-table .cell {
  font-size: 18px;
}

</style>
