import request from "@/service/";


export const addDepartment = (data) => {
    return request.post(
        `${window.VUE_APP_URL}/api/refs/departments`,
        data
    );
}

export const updateDepartment = (data) => {
    return request.put(
        `${window.VUE_APP_URL}/api/refs/departments`,
        data
    );
}


export const    getDepartmentList = (params) => {
    return request.get(
        `${window.VUE_APP_URL}/api/refs/departments`,
        {
            params: params
        }
    );
}

export const getDepartmentTreeData = (params) => {
    return request.get(
        `${window.VUE_APP_URL}/api/refs/departments/treeData`,
        params
    );
}

export const getDepartmentById = (id) => {
    return request.get(
        `${window.VUE_APP_URL}/api/refs/departments/${id}`,
    );
}

export const deleteOneById = (id) => {
    return request.delete(
        `${window.VUE_APP_URL}/api/refs/departments/${id}`,
    );
}
